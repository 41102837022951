import { MenuItem, Select } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetConfigs } from "../api";
import { Config, ConfigOrder } from "../types";

interface SelectConfigProps {
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  emptyOptionLabel?: string;
  configId?: number;
  onChange: (configId: number | undefined) => void;
}

export default function SelectConfig(props: SelectConfigProps): ReactElement {
  const [configs, setConfigs] = useState<Config[]>([]);

  useEffect(() => {
    apiGetConfigs(ConfigOrder.YEAR_DESC, 0, 100)
      .then((data) => {
        setConfigs(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <Select
      size="small"
      variant={props.variant}
      fullWidth={props.fullWidth}
      value={props.configId?.toString() || "ALL"}
      onChange={(event) => {
        props.onChange(
          event.target.value !== "ALL" ? Number(event.target.value) : undefined
        );
      }}
    >
      {props.emptyOptionLabel && (
        <MenuItem value="ALL">{props.emptyOptionLabel}</MenuItem>
      )}
      {configs.map((x) => (
        <MenuItem value={x.id}>{x.year}</MenuItem>
      ))}
    </Select>
  );
}
