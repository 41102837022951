import { Box, Button, Container, Paper } from "@mui/material";
import { MouseEventHandler, ReactElement } from "react";

interface LoginFormProps {
  onLoginButtonClick: MouseEventHandler<HTMLButtonElement>;
}

export default function LoginForm(props: LoginFormProps): ReactElement {
  return (
    <Container component="main" maxWidth="xs">
      <Paper style={{ padding: "1rem", marginTop: "3rem" }} elevation={6}>
        <Box my="3rem">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={props.onLoginButtonClick}
          >
            Login to the Application
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
