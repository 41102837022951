import { AuthenticatedTemplate } from "@azure/msal-react";
import { Add, Delete, Edit, People, Visibility } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmDialog from "../Components/ConfirmDialog";
import TableHeaderCell from "../Components/TableHeaderCell";
import { apiDeleteConfig, apiGetConfigCount, apiGetConfigs } from "../api";
import { useSearchParam, useSearchParamNumber } from "../hooks";
import { Config, ConfigOrder } from "../types";
import { formatDateOnly } from "../utils";

export default function ConfigsPage(): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [configs, setConfigs] = useState<Config[]>([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 10);
  const [order, setOrder] = useSearchParam<ConfigOrder>(
    "order",
    ConfigOrder.YEAR_DESC
  );

  const [selectedConfigId, setSelectedConfigId] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    apiGetConfigCount()
      .then((data) => setCount(data))
      .catch((e) => setErrorMessage(e.message));
  }, []);

  useEffect(() => {
    if (isDeleting === true) return;

    setIsLoading(true);
    apiGetConfigs(order, page * rowsPerPage, rowsPerPage)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");
        setConfigs(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
        setConfigs([]);
      });
  }, [page, rowsPerPage, order, isDeleting]);

  const confirmDelete = () => {
    setIsDeleting(true);
    apiDeleteConfig(selectedConfigId)
      .then(() => {
        setIsDeleting(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsDeleting(false);
        setErrorMessage(e.message);
      });

    setConfirmDialogOpen(false);
  };

  const cancelDelete = () => setConfirmDialogOpen(false);

  return (
    <AuthenticatedTemplate>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop open={isLoading || isDeleting}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <Box mb={2}>
          <Button
            component={Link}
            to="/config/new"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<Add />}
          >
            Create
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell
                  title="ID"
                  order={order}
                  orderAsc={ConfigOrder.ID_ASC}
                  orderDesc={ConfigOrder.ID_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Year"
                  order={order}
                  orderAsc={ConfigOrder.YEAR_ASC}
                  orderDesc={ConfigOrder.YEAR_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Start Date"
                  order={order}
                  orderAsc={ConfigOrder.START_DATE_ASC}
                  orderDesc={ConfigOrder.START_DATE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="End Date"
                  order={order}
                  orderAsc={ConfigOrder.END_DATE_ASC}
                  orderDesc={ConfigOrder.END_DATE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {configs.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{formatDateOnly(row.startDate)}</TableCell>
                  <TableCell>{formatDateOnly(row.endDate)}</TableCell>
                  <TableCell>
                    <Button
                      component={Link}
                      to={"/employees?configId=" + row.id}
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<People />}
                    >
                      Employees
                    </Button>
                    {!row.canUpdate && (
                      <Button
                        component={Link}
                        to={`/config/${row.id}`}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Visibility />}
                        style={{ marginLeft: 5 }}
                      >
                        View
                      </Button>
                    )}
                    {row.canUpdate && (
                      <Button
                        component={Link}
                        to={`/config/${row.id}`}
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Edit />}
                        style={{ marginLeft: 5 }}
                      >
                        Edit
                      </Button>
                    )}
                    {row.canDelete && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        startIcon={<Delete />}
                        onClick={() => {
                          setSelectedConfigId(row.id);
                          setConfirmDialogOpen(true);
                        }}
                        style={{ marginLeft: 5 }}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton={true}
          showLastButton={true}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        />
      </Container>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Delete selected configuration?"
        message="Are you sure you want to delete the selected configuration? This will delete all user submissions made under this configuration. This action cannot be reversed."
        confirm="Delete"
        dismiss="Cancel"
        invertButtonColors={true}
        onConfirm={confirmDelete}
        onDismiss={cancelDelete}
      />
    </AuthenticatedTemplate>
  );
}
