import { ReactElement } from "react";
import appPackage from "../../package.json";

export default function Footer(): ReactElement {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "0.8rem",
        padding: 15,
      }}
    >
      © DeGolyer and MacNaughton | Version {appPackage.version}
    </div>
  );
}
