import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
} from "@mui/material";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { apiCallGetAcknowledgment, apiSubmitAcknowledgment } from "../api";
import { useSearchParamNumber } from "../hooks";
import { GetAcknowledgmentResponse } from "../types";
import SelectConfig from "./SelectConfig";

export default function AcknowledgmentForm(): ReactElement {
  const [configId, setConfigId] = useSearchParamNumber("configId", 0);
  const [selectedConfigId, setSelectedConfigId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useState(0);
  const [empCode, setEmpCode] = useState("");
  const [empName, setEmpName] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ackDate, setAckDate] = useState<Date>();
  const [canSubmit, setCanSubmit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitted(Boolean(ackDate));
  }, [ackDate]);

  useEffect(() => {
    setIsLoading(true);

    apiCallGetAcknowledgment(configId)
      .then((data: GetAcknowledgmentResponse) => {
        setIsLoading(false);
        setIsLoaded(true);
        setErrorMessage("");

        setSelectedConfigId(data.configId);

        setYear(data.year);
        setEmpCode(data.empCode);
        setEmpName(data.empName);
        setEmpEmail(data.empEmail);
        setMessage(data.message);
        setAckDate(data.ackDate ? new Date(data.ackDate) : undefined);
        setCanSubmit(data.canSubmit);
        setChecked(Boolean(data.ackDate));
      })
      .catch((e) => {
        setIsLoading(false);
        setIsLoaded(false);
        setErrorMessage(e.message);

        setSelectedConfigId(configId);
      });
  }, [configId]);

  const changeHandler = (_: ChangeEvent<{}>, checked: boolean) => {
    setChecked(checked);
  };

  const submitHandler = () => {
    setSubmitting(true);

    apiSubmitAcknowledgment()
      .then(() => {
        setSubmitting(false);
        setAckDate(new Date());
      })
      .catch((e) => {
        setSubmitting(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container maxWidth="md" style={{ marginTop: 20 }}>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Box textAlign="right" marginBottom={2}>
        <SelectConfig
          configId={selectedConfigId}
          onChange={(v) => setConfigId(v || 0)}
        />
      </Box>
      <Paper elevation={3}>
        <Box p={3}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {isLoaded && (
            <>
              <TextField
                label="Year"
                value={year}
                variant="filled"
                size="small"
                margin="dense"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Employee Code"
                value={empCode}
                variant="filled"
                size="small"
                margin="dense"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Employee Name"
                value={empName}
                variant="filled"
                size="small"
                margin="dense"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Employee Email"
                value={empEmail}
                variant="filled"
                size="small"
                margin="dense"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Acknowledgment Date"
                value={ackDate?.toLocaleString() || "-"}
                variant="filled"
                size="small"
                margin="dense"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <Box mt={1}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={message}
                    checked={checked}
                    disabled={submitted || !canSubmit}
                    onChange={changeHandler}
                  />
                </FormGroup>
              </Box>
              <Box mt={2}>
                {submitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={submitted || !canSubmit || !checked}
                    onClick={submitHandler}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
}
