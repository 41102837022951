import { MsalProvider } from "@azure/msal-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { msalInstance } from "./auth";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import ConfigPage from "./Pages/ConfigPage";
import ConfigsPage from "./Pages/ConfigsPage";
import EmployeesPage from "./Pages/EmployeesPage";
import HomePage from "./Pages/HomePage";
import theme from "./theme";

export default function Application(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/configs" element={<ConfigsPage />} />
            <Route path="/config/:id" element={<ConfigPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  );
}
