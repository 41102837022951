import { AuthenticatedTemplate } from "@azure/msal-react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import { MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiCreateConfig, apiGetConfig, apiUpdateConfig } from "../api";
import MessageDialog from "../Components/MessageDialog";
import { ConfigData } from "../types";
import { dateToISODate } from "../utils";

export default function ConfigPage(): ReactElement {
  const navigate = useNavigate();

  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadedId, setLoadedId] = useState(0);
  const [year, setYear] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [canUpdate, setCanUpdate] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [messageDialogText, setMessageDialogText] = useState("");

  useEffect(() => {
    if (!id) return;

    const configId: number = parseInt(id, 10);
    if (!configId) return;

    setIsLoading(true);

    apiGetConfig(configId)
      .then((config) => {
        setLoadedId(config.id);
        setYear(config.year.toString());
        setStartDate(config.startDate);
        setEndDate(config.endDate);
        setMessage(config.message);
        setCanUpdate(config.canUpdate);

        setIsLoading(false);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setCanSave(
      (id === "new" || canUpdate) &&
        !!year &&
        !!startDate &&
        !!endDate &&
        !!message
    );
  }, [id, canUpdate, year, startDate, endDate, message]);

  const saveHandler: MouseEventHandler<HTMLButtonElement> = () => {
    const data: ConfigData = {
      year: parseInt(year, 10),
      startDate,
      endDate,
      message,
    };

    if (!loadedId) {
      apiCreateConfig(data)
        .then((result) => {
          if (result) {
            setMessageDialogText(
              "Please check the following fields: " +
                JSON.stringify(result.errors)
            );
            setMessageDialogOpen(true);
          } else {
            navigate("/configs");
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    } else {
      apiUpdateConfig(loadedId, data)
        .then((result) => {
          if (result) {
            setMessageDialogText(
              "Please check the following fields: " +
                JSON.stringify(result.errors)
            );
            setMessageDialogOpen(true);
          } else {
            navigate("/configs");
          }
        })
        .catch((e) => {
          setErrorMessage(e.message);
        });
    }
  };

  const handleDialogDismiss = () => {
    setMessageDialogOpen(false);
  };

  return (
    <AuthenticatedTemplate>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <Paper elevation={3}>
          <Box p={3}>
            <TextField
              label="Year"
              value={year}
              onChange={(event) => setYear(event.target.value)}
              type="number"
              variant="filled"
              size="small"
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Start date"
              value={dateToISODate(startDate)}
              onChange={(event) => setStartDate(event.target.value)}
              type="date"
              variant="filled"
              size="small"
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End date"
              value={dateToISODate(endDate)}
              onChange={(event) => setEndDate(event.target.value)}
              type="date"
              variant="filled"
              size="small"
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              variant="filled"
              size="small"
              margin="dense"
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
            <Box mt={2}>
              <Button
                disabled={!canSave}
                variant="contained"
                color="primary"
                onClick={saveHandler}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <MessageDialog
        open={messageDialogOpen}
        title="Error saving configuration"
        message={messageDialogText}
        dismiss="Dismiss"
        onDismiss={handleDialogDismiss}
      />
    </AuthenticatedTemplate>
  );
}
