import { AuthenticatedTemplate } from "@azure/msal-react";
import { GetApp, Send, Sync } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import ConfirmDialog from "../Components/ConfirmDialog";
import SelectConfig from "../Components/SelectConfig";
import TableFilterCell from "../Components/TableFilterCell";
import TableHeaderCell from "../Components/TableHeaderCell";
import {
  apiExportEmployeeInfoAsExcel,
  apiGetEmployeeInfoCount,
  apiGetEmployeeInfos,
  apiSendEmailsForConfig,
  apiSyncEmployeesForConfig,
  apiUpdateEmployeeInfo,
  apiUpdateEmployeeInfos,
} from "../api";
import { useSearchParam, useSearchParamNumber } from "../hooks";
import { EmployeeInfo, EmployeeInfoOrder } from "../types";
import { formatDate, saveBlob } from "../utils";

export default function EmployeesPage(): ReactElement {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [employees, setEmployees] = useState<EmployeeInfo[]>([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useSearchParamNumber("page", 0);
  const [rowsPerPage, setRowsPerPage] = useSearchParamNumber("rowsPerPage", 25);
  const [configId, setConfigId] = useSearchParamNumber("configId", 0);
  const [empCode, setEmpCode] = useSearchParam<string>("empCode", "");
  const [empName, setEmpName] = useSearchParam<string>("empName", "");
  const [empEmail, setEmpEmail] = useSearchParam<string>("empEmail", "");
  const [divName, setDivName] = useSearchParam<string>("divName", "");
  const [order, setOrder] = useSearchParam<EmployeeInfoOrder>(
    "order",
    EmployeeInfoOrder.ID_ASC
  );

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    apiGetEmployeeInfoCount(
      configId || undefined,
      empCode,
      empName,
      empEmail,
      divName
    )
      .then((data) => setCount(data))
      .catch((e) => setErrorMessage(e.message));
  }, [configId, empCode, empName, empEmail, divName]);

  useEffect(() => {
    if (isStoring) return;

    setIsLoading(true);
    apiGetEmployeeInfos(
      configId || undefined,
      empCode,
      empName,
      empEmail,
      divName,
      order,
      page * rowsPerPage,
      rowsPerPage
    )
      .then((data) => {
        setErrorMessage("");
        setIsLoading(false);
        setEmployees(data);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setIsLoading(false);
        setEmployees([]);
      });
  }, [
    configId,
    empCode,
    empName,
    empEmail,
    divName,
    order,
    page,
    rowsPerPage,
    isStoring,
  ]);

  const includeAll = (checked: boolean) => {
    const ids: number[] = employees.map((x) => x.id);

    setIsStoring(true);
    apiUpdateEmployeeInfos(ids, checked)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const includeItem = (id: number, checked: boolean) => {
    setIsStoring(true);
    apiUpdateEmployeeInfo(id, checked)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const sendEmails = () => {
    if (!configId) return;

    setIsStoring(true);
    apiSendEmailsForConfig(configId)
      .then(() => {
        setIsStoring(false);
      })
      .catch((e: any) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const exportToExcel = () => {
    apiExportEmployeeInfoAsExcel(
      configId || undefined,
      empCode,
      empName,
      empEmail,
      divName,
      order
    )
      .then((blob) => saveBlob(blob, "Employees.xlsx"))
      .catch((e) => setErrorMessage(e.message));
  };

  const syncEmployees = () => {
    if (!configId) return;

    setIsStoring(true);
    apiSyncEmployeesForConfig(configId)
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const totalCount: number = employees.length;
  const includedCount: number = employees.reduce(
    (count, x) => (x.isIncluded ? count + 1 : count),
    0
  );
  const excludedCount: number = employees.reduce(
    (count, x) => (x.isIncluded ? count : count + 1),
    0
  );

  return (
    <AuthenticatedTemplate>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Backdrop open={isLoading || isStoring}>
        <CircularProgress />
      </Backdrop>
      <Container maxWidth="xl" style={{ marginTop: 40 }}>
        <Box mb={2}>
          <Button
            color="secondary"
            variant="contained"
            size="small"
            startIcon={<GetApp />}
            onClick={exportToExcel}
          >
            Export to Excel
          </Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<Sync />}
            onClick={syncEmployees}
            disabled={!configId}
          >
            Sync Employees
          </Button>
          &nbsp;&nbsp;
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<Send />}
            onClick={() => {
              setConfirmDialogOpen(true);
            }}
            disabled={!configId}
          >
            Send Emails
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" padding="checkbox">
            <TableHead>
              <TableRow>
                <TableHeaderCell
                  title="Year"
                  order={order}
                  orderAsc={EmployeeInfoOrder.CONFIG_YEAR_ASC}
                  orderDesc={EmployeeInfoOrder.CONFIG_YEAR_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Code"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_CODE_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_CODE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Included"
                  order={order}
                  orderAsc={EmployeeInfoOrder.IS_INCLUDED_ASC}
                  orderDesc={EmployeeInfoOrder.IS_INCLUDED_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Name"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_NAME_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_NAME_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Email"
                  order={order}
                  orderAsc={EmployeeInfoOrder.EMP_EMAIL_ASC}
                  orderDesc={EmployeeInfoOrder.EMP_EMAIL_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Division"
                  order={order}
                  orderAsc={EmployeeInfoOrder.DIV_NAME_ASC}
                  orderDesc={EmployeeInfoOrder.DIV_NAME_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
                <TableHeaderCell
                  title="Acknowledge Date"
                  order={order}
                  orderAsc={EmployeeInfoOrder.ACK_DATE_ASC}
                  orderDesc={EmployeeInfoOrder.ACK_DATE_DESC}
                  onOrderChange={(o) => setOrder(o)}
                />
              </TableRow>
              <TableRow>
                <TableCell>
                  <SelectConfig
                    fullWidth
                    variant="standard"
                    emptyOptionLabel="All"
                    configId={configId || undefined}
                    onChange={(v) => setConfigId(v || 0)}
                  />
                </TableCell>
                <TableFilterCell
                  value={empCode}
                  onChange={(value) => setEmpCode(value)}
                />
                <TableCell>
                  <Checkbox
                    checked={totalCount > 0 && includedCount === totalCount}
                    indeterminate={
                      excludedCount > 0 && excludedCount < totalCount
                    }
                    onChange={(_, checked) => includeAll(checked)}
                  />
                </TableCell>
                <TableFilterCell
                  value={empName}
                  onChange={(value: string) => setEmpName(value)}
                />
                <TableFilterCell
                  value={empEmail}
                  onChange={(value: string) => setEmpEmail(value)}
                />
                <TableFilterCell
                  value={divName}
                  onChange={(value: string) => setDivName(value)}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.empCode}</TableCell>
                  <TableCell>
                    <Checkbox
                      disabled={!row.canUpdate}
                      checked={row.isIncluded}
                      onChange={(_, checked) => includeItem(row.id, checked)}
                    />
                  </TableCell>
                  <TableCell>{row.empName}</TableCell>
                  <TableCell>{row.empEmail}</TableCell>
                  <TableCell>{row.divName}</TableCell>
                  <TableCell>{formatDate(row.ackDate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton={true}
          showLastButton={true}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(_, p) => setPage(p)}
          onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
        />
      </Container>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Send emails?"
        message="Are you sure you want to send emails to all included employees in this configuration?"
        confirm="Yes"
        dismiss="No"
        invertButtonColors={true}
        onConfirm={() => {
          setConfirmDialogOpen(false);
          sendEmails();
        }}
        onDismiss={() => setConfirmDialogOpen(false)}
      />
    </AuthenticatedTemplate>
  );
}
