import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ReactElement } from "react";
import { msalInstance } from "../auth";
import AcknowledgmentForm from "../Components/AcknowledgmentForm";
import LoginForm from "../Components/LoginForm";

export default function HomePage(): ReactElement {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoginForm onLoginButtonClick={() => msalInstance.loginRedirect()} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AcknowledgmentForm />
      </AuthenticatedTemplate>
    </>
  );
}
